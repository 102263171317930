import { Heading, Body, HtmlContent, HStack, Tag, Box, Wrapper, Flex, Grid, Img } from 'designsystem';
import { FC } from 'react';
import HomeStoriesEvents from './HomeStoriesEvents';
import { HomeHeroEventFragment, HomeHeroKeyDateFragment, HomeHeroStoryFragment } from '../gql/cms';
import { useGetCmsImageProps } from 'shared';
import Link from 'next/link';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
    stories: HomeHeroStoryFragment[];
    keyDates: HomeHeroKeyDateFragment[];
    events: HomeHeroEventFragment[];
}

const HomeHero: FC<Props> = ({ stories, keyDates, events }) => {
    const { tokens } = useTheme();
    const [firstHeroStory, secondHeroStory, thirdHeroStory] = stories;
    const getImageProps = useGetCmsImageProps();
    return (
        <Box
            bg={tokens.SyntaxBackgroundNeutralDarkest}
            minH="480px"
            h={[null, null, null, '768px']}
            color={tokens.SyntaxTextColorOnDark}
            zIndex={1}
            position="relative"
            pb={[0, null, null, '256px']}
            display="flex"
        >
            <Wrapper display="flex" pt={7} pb={[5, 8, 10]}>
                <Box
                    position="absolute"
                    w="100%"
                    h="100%"
                    sx={{
                        inset: 0,
                        '@media (min-width: 1024px) and (max-width: 2090px)': {
                            width: '62% !important',
                        },
                    }}
                >
                    <StyledImg
                        {...getImageProps(firstHeroStory?.headerImage[0], firstHeroStory?.headerImage[0].alt, '100vw')}
                        fill
                    />
                    <Box
                        position="absolute"
                        inset="0"
                        bgGradient={`linear(to-b, rgba(0,0,0,0), ${tokens.SyntaxOverlayColorDefault}) 83%`}
                    />
                </Box>
                <Flex flexDir="row" grow={1} zIndex="base">
                    <Grid w="100%" zIndex={-1}>
                        <Flex
                            flexDir={['column']}
                            justifyContent={['flex-end']}
                            flexGrow={1}
                            gridColumn={['1 / -1', null, '1 / span 10', '1 / span 6']}
                        >
                            <Box>
                                <Link href={firstHeroStory?.uri ?? ''}>
                                    <Box pl={[0, null, 7]} pb={[7, null, 0]} pt={['328px', null, 0]}>
                                        <Heading variant={3} mb={4}>
                                            {firstHeroStory?.title}
                                        </Heading>
                                        <Body clamp={4} mb={7}>
                                            <HtmlContent html={firstHeroStory?.introText} />
                                        </Body>
                                        <HStack spacing={4}>
                                            <Tag>{firstHeroStory?.storyCategory[0].title}</Tag>
                                            <Body>by {firstHeroStory?.authorName}</Body>
                                        </HStack>
                                    </Box>
                                </Link>
                                <Box display={['none', null, null, 'block']}>
                                    <HomeStoriesEvents
                                        events={events}
                                        stories={[secondHeroStory, thirdHeroStory]}
                                        keyDates={keyDates}
                                    />
                                </Box>
                            </Box>
                        </Flex>
                    </Grid>
                </Flex>
            </Wrapper>
        </Box>
    );
};

const StyledImg = styled(Img)`
    object-fit: cover;
`;

export default HomeHero;
