import { ChakraGrid, NewsCard, Tag, Tile, Stack, Heading, HStack, Body, ListDivider } from 'designsystem';
import Link from 'next/link';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate, useGetCmsImageProps } from 'shared';
import { HomeHeroEventFragment, HomeHeroStoryFragment, HomeHeroKeyDateFragment } from '../gql/cms';

interface Props {
    stories: HomeHeroStoryFragment[];
    keyDates: HomeHeroKeyDateFragment[];
    events: HomeHeroEventFragment[];
}

const HomeStoriesEvents: FC<Props> = ({ stories, keyDates, events }) => {
    const getImageProps = useGetCmsImageProps();

    return (
        <ChakraGrid
            gridTemplateColumns={['1fr', null, null, '0.5fr 0.5fr 1fr']}
            alignItems="end"
            position={['static', null, null, 'absolute']}
            bottom={[null, null, '-198px']}
            zIndex={-1}
        >
            {stories?.map(story => (
                <Link key={story?.id} href={story?.uri || '/404'}>
                    <NewsCard
                        gridColumn={['1 / -1', '1 / span 1', '1 / span 1', '1 / span 1']}
                        image={getImageProps(story?.headerImage[0])}
                        title={story?.title}
                        meta={{
                            items: [],
                            tag: <Tag>{story?.storyCategory[0].title}</Tag>,
                        }}
                    />
                </Link>
            ))}
            {(events?.length > 0 || keyDates?.length > 0) && (
                <Tile
                    colorScheme="professionals"
                    px={[6, null, null, 15]}
                    py={[7, null, null, 0]}
                    order={[-1, null, null, 0]}
                    minH={['auto', null, null, '1000px']}
                >
                    <Stack direction="column" spacing={9} divider={<ListDivider />}>
                        {keyDates.length > 0 && (
                            <Stack direction="column" spacing={[7, null, 9]}>
                                <Heading variant={3} pb={[4, null, 0]}>
                                    <FormattedMessage defaultMessage="Key Dates" />
                                </Heading>
                                {keyDates.map(keyDate => (
                                    <Link key={keyDate.id} href={keyDate.linkObject?.url || ''}>
                                        <Heading variant={4}>{keyDate.text}</Heading>
                                        <HStack>
                                            <Body>
                                                <FormattedDate date={keyDate.date} variant="DATE-MEDIUM" /> • by{' '}
                                                {keyDate.publisher}
                                            </Body>
                                        </HStack>
                                    </Link>
                                ))}
                            </Stack>
                        )}
                        {events.length > 0 && (
                            <Stack direction="column" spacing={[7, null, 9]}>
                                <Heading variant={3} pb={[4, null, 0]}>
                                    <FormattedMessage defaultMessage="Events" />
                                </Heading>
                                {events.map(event => (
                                    <Link key={event.id} href={event.linkObject?.url || ''}>
                                        <Heading variant={4}>{event.text}</Heading>
                                        <HStack>
                                            <Body>
                                                <FormattedDate date={event.date} variant="DATE-MEDIUM" /> •{' '}
                                                <FormattedMessage defaultMessage="by" /> {event.publisher}
                                            </Body>
                                        </HStack>
                                    </Link>
                                ))}
                            </Stack>
                        )}
                    </Stack>
                </Tile>
            )}
        </ChakraGrid>
    );
};

export default HomeStoriesEvents;
