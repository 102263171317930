import { Cta, getColorSchemeFromString, Body, Stack, TextButton } from 'designsystem';
import Link from 'next/link';
import { FC } from 'react';
import { useGetCmsImageProps } from 'shared';
import { HomeCtaFragment } from '../gql/cms';

interface Props {
    cta: HomeCtaFragment;
}

const HomeCTA: FC<Props> = ({ cta }) => {
    const getImageProps = useGetCmsImageProps();
    return (
        cta && (
            <Cta
                title={cta.heading}
                colorScheme={getColorSchemeFromString(cta.color)}
                image={getImageProps(cta.image[0])}
            >
                <Body>{cta.description}</Body>
                <Stack spacing={3}>
                    {cta.links.map(link => (
                        <Link
                            href={(link.linkObject.element?.uri ?? link.linkObject.url) || '/404'}
                            target={!link.linkObject.element?.uri ? '_blank' : undefined}
                            key={link.id}
                        >
                            <TextButton as="div" size="s">
                                {link.label}
                            </TextButton>
                        </Link>
                    ))}
                </Stack>
            </Cta>
        )
    );
};

export default HomeCTA;
